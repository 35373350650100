$primary-color: rgba(0, 0, 0, 0.4);
$muted-color: rgba(255, 255, 255, 0.2);
$brighter-color: rgba(255, 255, 255, 0.8);
$green: #6ddc8b;

.rc-steps {
  font-size: 0;
  width: 100%;
  line-height: 1.5;
  display: -ms-flexbox;
  display: flex;
}
.rc-steps,
.rc-steps * {
  box-sizing: border-box;
}
.rc-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
}
.rc-steps-item:last-child {
  -ms-flex: none;
  flex: none;
}
.rc-steps-item:last-child .rc-steps-item-tail,
.rc-steps-item:last-child .rc-steps-item-title:after {
  display: none;
}
.rc-steps-item-icon,
.rc-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.rc-steps-item-icon {
  border: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 26px;
  font-size: 14px;
  margin-right: 8px;
  transition: background-color 0.3s, border-color 0.3s;
}
.rc-steps-item-icon > .rc-steps-icon {
  line-height: 1;
  top: -1px;
  color: $primary-color;
  position: relative;
}
.rc-steps-item-icon > .rc-steps-icon.rcicon {
  font-size: 12px;
  position: relative;
  top: -2px;
}
.rc-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}
.rc-steps-item-tail:after {
  content: '';
  display: inline-block;
  background: $muted-color;
  height: 3px;
  border-radius: 1px;
  width: 100%;
  transition: background 0.3s;
}
.rc-steps-item-content {
  margin-top: 3px;
}
.rc-steps-item-title {
  font-size: 14px;
  margin-bottom: 4px;
  color: white;
  font-weight: bold;
  display: inline-block;
  padding-right: 10px;
  position: relative;
}
.rc-steps-item-title:after {
  content: '';
  height: 1px;
  width: 1000px;
  background: #e9e9e9;
  display: block;
  position: absolute;
  top: 0.55em;
  left: 100%;
}
.rc-steps-item-description {
  font-size: 12px;
  color: #999;
}
.rc-steps-item-wait .rc-steps-item-icon {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
  color: rgba(255, 255, 255, 0.3);
  font-weight: bold;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #ccc;
}
.rc-steps-item-wait .rc-steps-item-title {
  color: rgba(255, 255, 255, 0.6);
}
.rc-steps-item-wait .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-wait .rc-steps-item-description {
  color: rgba(255, 255, 255, 0.75);
}
.rc-steps-item-wait .rc-steps-item-tail:after {
  background-color: $muted-color;
}
.rc-steps-item-process .rc-steps-item-icon {
  border-color: white;
  border: 1px solid;
  background-color: #fff;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: $primary-color;
  font-weight: bold;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: $primary-color;
}
.rc-steps-item-process .rc-steps-item-title {
  color: rgba(255, 255, 255, 1);
}
.rc-steps-item-process .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-process .rc-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.rc-steps-item-process .rc-steps-item-tail:after {
  background-color: $muted-color;
}
.rc-steps-item-process .rc-steps-item-icon {
  background: $primary-color;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #fff;
}
.rc-steps-item-finish .rc-steps-item-icon {
  border-color: $primary-color;
  background-color: $green;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: $primary-color;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon::before {
  content: '';
  top: 2px;
  width: 6px;
  border-bottom: 2px solid $brighter-color;
  height: 11px;
  border-right: 2px solid $brighter-color;
  /* rotate the mirrored L to make it a checkbox */
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: $primary-color;
}
.rc-steps-item-finish .rc-steps-item-title {
  color: $brighter-color;
}
.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: $primary-color;
}
.rc-steps-item-finish .rc-steps-item-description {
  color: rgba(0, 0, 0, 0.43);
}
.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: $brighter-color;
}
.rc-steps-item-error .rc-steps-item-icon {
  border-color: #f50;
  background-color: #fff;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #f50;
}
.rc-steps-item-error .rc-steps-item-title {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-error .rc-steps-item-description {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}
.rc-steps-item.rc-steps-next-error .rc-steps-item-title:after {
  background: #f50;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
  margin-right: 10px;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item:last-child {
  margin-right: 0;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-tail {
  display: none;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-description {
  max-width: 135px;
}
.rc-steps-item-custom .rc-steps-item-icon {
  background: none;
  border: 0;
  width: auto;
  height: auto;
}
.rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  font-size: 20px;
  top: 1px;
  width: 20px;
  height: 20px;
}
.rc-steps-item-custom.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: $primary-color;
}
.rc-steps-small .rc-steps-item-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 18px;
  font-size: 12px;
  margin-right: 10px;
}
.rc-steps-small .rc-steps-item-icon > .rc-steps-icon {
  font-size: 12px;
  font-size: 9px \9;
  transform: scale(0.75);
  top: -1px;
}
.rc-steps-small .rc-steps-item-content {
  margin-top: 0;
}
.rc-steps-small .rc-steps-item-title {
  font-size: 12px;
  margin-bottom: 4px;
  color: #666;
  font-weight: bold;
}
.rc-steps-small .rc-steps-item-description {
  font-size: 12px;
  color: #999;
}
.rc-steps-small .rc-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
.rc-steps-small .rc-steps-item-tail:after {
  height: 1px;
  border-radius: 1px;
  width: 100%;
}
.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  border-radius: 0;
  border: 0;
  background: none;
}
.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  font-size: 20px;
  top: -2.5px;
  transform: none;
}
.rc-steps-vertical {
  display: block;
}
.rc-steps-vertical .rc-steps-item {
  display: block;
  overflow: visible;
}
.rc-steps-vertical .rc-steps-item-icon {
  float: left;
}
.rc-steps-vertical .rc-steps-item-icon-inner {
  margin-right: 16px;
}
.rc-steps-vertical .rc-steps-item-content {
  min-height: 48px;
  overflow: hidden;
  display: block;
}
.rc-steps-vertical .rc-steps-item-title {
  line-height: 26px;
}
.rc-steps-vertical .rc-steps-item-title:after {
  display: none;
}
.rc-steps-vertical .rc-steps-item-description {
  padding-bottom: 12px;
}
.rc-steps-vertical .rc-steps-item-tail {
  position: absolute;
  left: 13px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 30px 0 4px 0;
}
.rc-steps-vertical .rc-steps-item-tail:after {
  height: 100%;
  width: 1px;
}
.rc-steps-vertical.rc-steps-small .rc-steps-item-tail {
  position: absolute;
  left: 9px;
  top: 0;
  padding: 22px 0 4px 0;
}
.rc-steps-vertical.rc-steps-small .rc-steps-item-title {
  line-height: 18px;
}
.rc-steps-label-vertical .rc-steps-item {
  overflow: visible;
}
.rc-steps-label-vertical .rc-steps-item-tail {
  padding: 0px 24px;
  margin-left: 65px;
}
.rc-steps-label-vertical .rc-steps-item-content {
  display: block;
  text-align: center;
  margin-top: 8px;
  width: 135px;
}
.rc-steps-label-vertical .rc-steps-item-icon {
  display: inline-block;
  margin-left: 52px;
}
.rc-steps-label-vertical .rc-steps-item-title {
  padding-right: 0;
}
.rc-steps-label-vertical .rc-steps-item-title:after {
  display: none;
}
.rc-steps-label-vertical .rc-steps-item-description {
  text-align: left;
}
.rc-steps-dot .rc-steps-item-tail {
  width: 100%;
  top: 1px;
  margin: 0 0 0 50px;
  padding: 0;
}
.rc-steps-dot .rc-steps-item-tail:after {
  height: 3px;
}
.rc-steps-dot .rc-steps-item-icon {
  padding-right: 0;
  width: 5px;
  height: 5px;
  line-height: 5px;
  border: 0;
  margin-left: 52px;
}
.rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
}
.rc-steps-dot .rc-steps-item-process .rc-steps-dot .rc-steps-item-icon {
  top: -1px;
  width: 7px;
  height: 7px;
  line-height: 7px;
}
.rc-steps-dot .rc-steps-item-process .rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
  border-radius: 3.5px;
}
