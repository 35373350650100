.table-filter {
  color: #000;
}

.tableContainer {
  display: block;
  max-width: 100%;
}

.tableWrap {
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

table {
  width: 100%;
  border-spacing: 0;

  th,
  td {
    margin: 0;

    /* The secret sauce */
    /* Each cell should grow equally */
    // width: 1%;
    /* But "collapsed" cells should be as small as possible */
    &.collapse {
      width: 0.0000000001%;
    }
  }

  tr > th:first-child {
    width: 50px;
  }

  td.icon > i {
    cursor: pointer;
  }
}

.details-loader {
  text-align: center;
  width: 100%;
  margin: 20px;
}
