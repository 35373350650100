@import '~fe-fabric/sass/utils/variables/themes/dark/_color.scss';
@import '~@muse/styles/muse';
@import '~@muse/styles/css/muse-dark';

// Import the component styles
@import 'containers/Login';
@import 'containers/Home';
@import 'containers/NotFound';

html,
body,
#root {
  height: 100%;
  background-color: var(--background-page);
}
body,
#root {
  display: flex;
  flex-direction: column;
}

.mainContainer {
  flex: 1 0 auto;
}

.muse-footer {
  flex-shrink: 0;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.fe-hero {
  margin-top: -12px;
  padding: 32px 20px 20px 20px;
  background-color: var(--background-page);
}

div.redirection {
  width: 100%;
  height: 100px;
  text-align: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

.loaderDiv {
  position: absolute;
  padding-top: 70px;
  z-index: 10;
  text-align: center;
  width: 100%;
}

.docs {
  h1 {
    font-size: 14px;
  }
}

.muse-top-nav {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
}

a {
  text-decoration: none;
}

.muse-data-table {
  flex-direction: column;
}

.muse-data-table table tbody tr td {
  text-overflow: ellipsis;
  overflow: hidden;
}

.muse-data-table:not(table, .muse-data-table__toolbar, .muse-data-table__footer) {
  padding: 0;
}

.muse-data-table table {
  margin-bottom: 0;
}

.muse-data-table table tbody tr.integration-details > td {
  padding: 0;
}

.legend {
  margin-right: 20px;
}

.swatch {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.disabled {
  .swatch {
    background-color: var(--text-disabled);
  }
}
.ok {
  .swatch {
    background-color: var(--feedback-success);
  }
}
.review {
  .swatch {
    background-color: var(--risk-high);
  }
}
.warning {
  .swatch {
    background-color: var(--risk-medium);
  }
}
.critical {
  .swatch {
    background-color: var(--risk-critical);
  }
}
