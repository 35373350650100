.column-list {
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
  list-style-type: none;
  column-rule: solid 1px var(--border);
  column-gap: 16px;
  padding-top: 8px;
  padding-bottom: 16px;

  li {
    text-align: left;
    padding-left: 16px;
    button {
      cursor: pointer;
      color: var(--action-primary);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.header {
  scroll-margin-top: 72px,
}

.label {
  padding-left: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.total {
  color: var(--action-secondary--deemphasis);
  padding-left: 8px;
}
