.Login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: block;
    text-align: center;

    h1,
    h4 {
      margin: 0;
      margin-bottom: 0.75rem;

      i {
        margin-right: 0.5rem;
      }
    }

    button {
      margin-top: 0.5rem;
      i {
        margin-right: 0.5rem;
      }
    }
  }
}
