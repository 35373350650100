@import '~fe-fabric/sass/utils/variables/themes/dark/_color.scss';

.Home {

  padding-top: 60px;

  .fe-hero {
    margin-top: -16px !important;
  }

  .margin-search {
    margin-top: 19px;
    margin-bottom: 19px;
    width: 350px;
  }
}

.action-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.action-buttons {
  display: flex;

  div {
    display: flex;
    align-items: center;
  }

  .muse-popover {
    muse-icon {
      padding-top: 8px;
    }
  }

  .muse-tooltip {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.spacer {
  height: 10px;
}

.empty-message {
  padding-top: 200px;
  padding-bottom: 200px;
  text-align: center;

  .empty-image {
    margin-bottom: 20px;
  }
  .muse-btn {
    margin-top: 20px;
  }
}


