.AvailableIntegration {
  display: flex;
  align-items: center;

  padding: 15px;
  margin-bottom: 15px;

  background-color: var(--background-surface);

  .item:first-child {
    flex: 0 0 50px;
    margin: 0;
    margin-right: 15px;
  }

  .item:last-child {
    flex: 0 0 35px;
    margin: 0;
    margin-left: 15px;
    text-align: center;
  }

  .item {
    flex-grow: 1;
    min-width: 0;

    p.integration-name {
      margin: 0;
      margin-bottom: 5px;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
      }
    }

    p.company-name {
      margin-top: 0px;
      margin-bottom: 0px;
      overflow: hidden;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #949494;
    }
  }

  button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    height: 35px;
    width: 35px;

    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 0.1);
    }

    i.fe-icon--right {
      margin: 0;
      color: #10b8dc;
    }
  }

  .integration-image{
    border-radius: 5px;
  }
}
