.Install {
  padding-top: 60px;

  .InstallSticky {
    position: fixed;
    width: 100%;
    top: 60px;
    z-index: 1;

    h1 {
      margin-bottom: 15px;
    }
  }

  .InstallDescription {
    margin-bottom: 25px;
  }

  .StepHero {
    width: 100%;
    background: #0e1a2c;
    padding: 25px 20px;
    -webkit-box-shadow: 0 8px 30px -6px rgba(0, 0, 0, 0.9);
    -moz-box-shadow: 0 8px 30px -6px rgba(0, 0, 0, 0.9);
    box-shadow: 0 8px 30px -6px rgba(0, 0, 0, 0.9);

    h2 {
      margin: 0;
      display: inline-block;
    }

    .StepButtons {
      margin-top: -3px;
      float: right;

      .instance-picker-wrapper {
        display: inline-block;
      }

      .top-button {
        vertical-align: top;
      }

      .instance-picker-error-msg {
        color: #ea475b;
        font-size: 10px;
        line-height: 22px;
        font-weight: bold;
      }

      button.step-progress {
        margin-right: 1rem;
      }

      button.exit-preview {
        margin-left: 1rem;
        i {
          margin-right: 10px;
        }
      }

      div.instance-picker {
        margin-right: 1rem;
      }
    }
  }

  .StepContainer {
    margin: auto;
    max-width: 956px;
    padding-top: 30px;
    font-size: 18px;
    padding-top: 320px;

    p {
      margin: 1.5rem 0rem;
      line-height: 32px;

      img {
        display: block;
        margin: 0 auto;
        max-width: 756px;
      }
    }

    a {
      color: #42b8df;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: #3493b2;
      }
    }

    li {
      margin: 1.2rem 0rem;
    }

    .InputContainer {
      margin-top: 1.5rem;

      i {
        margin-right: 10px;
      }

      .InputStore {
        padding-top: 30px;
        padding-bottom: 30px;
        background: rgba(255, 255, 255, 0.075);
        border-radius: 8px;

        span.fe-input-hint-text {
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
        }
      }
    }

    .VerificationStep {
      margin-top: 2.5rem;
      text-align: center;

      .vs-icon-wrapper {
        margin: 0 auto;
        height: 63px;
        width: 63px;
        background: white;
        border-radius: 50%;
      }

      .caption {
        margin-bottom: 1.5rem;
      }

      .vs-icon {
        color: #7cdc86;
        line-height: 64px;
        font-size: 65px;
        display: block;
      }

      .vs-icon-danger {
        color: #b2282c;
        line-height: 64px;
        font-size: 65px;
        display: block;
      }

      .success-text {
        margin-top: 1.5rem;
      }
    }
  }

  .modalParagraph {
    margin-bottom: 25px;
  }
}

.steps-missing {
  text-align: center;
}

.input-wrapper {
  margin-bottom: 20px;
}
