@import '~fe-fabric/sass/utils/variables/themes/dark/_color.scss';

.EnabledIntegrations {

  div.show-hide-modal-content {
    h5 {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }

    pre {
      font-size: 14px;
      padding: 20px 10px;
      background: black;
      border-radius: 4px;
    }
  }

  table {
    tr.expanded {
      td.icon > i {
        transform: rotate(90deg);
        color: #f5f5f5;
      }
    }

    tr > td.integration-name {
      font-weight: bold;
    }

    div.expanded-content,
    div.integration-options {
      div.content-pane {
        padding: 15px;
        border-radius: 4px;
        background-color: var(--background-overlay);
        text-align: left;

        h4.heading {
          margin-top: 10px;
          font-size: 16px;
          font-weight: normal;
          text-transform: uppercase;
          letter-spacing: 2px;
          padding-bottom: 5px;
        }
        .action-buttons {
          justify-content: flex-end;
          &> muse-button {
            padding-right: 16px;
          }
        }

        div.flex-column {
          display: flex;

          div.value {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 15px;
            padding-top: 6px;
          }
        }

        div.col-md-6 {
          margin-bottom: 1.5rem;
        }

        div.integration-key {
          color: #bfbfbf;
        }
      }
    }
  }
}
